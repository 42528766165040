import { Percent, Lock } from 'react-feather';
import { MdMenuBook, MdOutlineRateReview, MdOutlineQueryStats } from 'react-icons/md';
import { RiAdvertisementLine } from 'react-icons/ri';
import { SiMicrodotblog } from 'react-icons/si';
import { TbReport } from 'react-icons/tb';
import { IoTicketSharp } from "react-icons/io5";
// import TablesRoutes from '../../router/routes/Tables';

export default [
  {
    id: 'ads',
    title: 'Ads',
    icon: (
      <div>
        <RiAdvertisementLine size={12} />
      </div>
    ),
    disabled: true,
    children: [
      // {
      //   id: 'adsoverview',
      //   title: 'Ads Overview',
      //   navLink: '/apps/ads/overview'
      // },
      // {
      //   id: 'addads',
      //   title: 'Ads Campaign Builder',
      //   navLink: '/apps/ads/new_ad'
      // },
      {
        id: 'adds V2',
        title: 'Ads Pro',
        navLink: '/apps/ads/ads-pro'
      }
    ]
  },
  {
    id: 'business_overview_analytics',
    title: 'Business Overview',
    icon: <MdOutlineQueryStats size={12} />,
    children: [
      {
        id: 'graphs_visualization',
        title: 'Delivery Insights',
        navLink: '/apps/business-overview/delivery-insights'
      },
      {
        id: 'order_insights',
        title: 'Order Insights',
        navLink: '/apps/business-overview/order-insights'
      },
      {
        id: 'pos_integration',
        title: 'Revenue Projections',
        navLink: '/apps/business-overview/revenue-projections'
      },
       {
       id: 'rating_analysis',
       title: 'Dishwise Revenue & Rating Analysis',
       navLink: '/apps/reviews_and_reputation/rating_analysis'
      },
    ]
  },
  {
    id: 'reviews_and_reputation',
    title: 'ORM',
    icon: <MdOutlineRateReview size={12} />,
    children: [
      // {
      //   id: 'reviews_visualization',
      //   title: 'Sentiment Analysis',
      //   navLink: '/apps/reviews_and_reputation/visualization'
      // },
      // {
      //   id: 'rating_analysis',
      //   title: 'Dishwise Revenue & Rating Analysis',
      //   navLink: '/apps/reviews_and_reputation/rating_analysis'
      // },
      {
        id: 'Reviews',
        title: 'Reputation Management',
        navLink: '/apps/orm/reputation-management'
      },
      {
        id: 'automation',
        title: 'Automation',
        navLink: '/apps/reviews_and_reputation/automation'
      }
      // {
      //   id: 'CRM',
      //   title: 'Ticketing System',
      //   navLink: '/apps/reviews_and_reputation/ticketing'
      // }
      // {
      //   id: 'facebook_reviews',
      //   title: 'Facebook',
      //   navLink: '/apps/reviews_and_reputation/facebook'
      // },
      // {
      //   id: 'google_reviews',
      //   title: 'Google Reviews',
      //   navLink: '/apps/reviews_and_reputation/google'
      // },
      // {
      //   id: 'customer_feedback',
      //   title: 'Customer Feedback',
      //   navLink: '/apps/reviews_and_reputation/customer_feedback',
      //   icon: (
      //     <div>
      //       <Lock size={12} />
      //     </div>
      //   ),
      //   disabled: true
      // },
      // {
      //   id: 'responding_to_customers',
      //   title: 'Responding to Customers',
      //   navLink: '/apps/reviews_and_reputation/responding_to_customers',
      //   icon: (
      //     <div>
      //       <Lock size={12} />
      //     </div>
      //   ),
      //   disabled: true
      // },
      // {
      //   id: 'sentiment_analysis_and_areas_of_improvement',
      //   title: 'Sentiment Analysis - Areas of Improvement',
      //   navLink: '/apps/reviews_and_reputation/sentiment_analysis_and_areas_of_improvement',
      //   icon: (
      //     <div>
      //       <Lock size={12} />
      //     </div>
      //   ),
      //   disabled: true
      // }
    ]
  },
  {
    id: 'crm',
    title: 'CRM',
    icon: <IoTicketSharp size={12} />,
    children: [
      {
        id: 'ticketing',
        title: 'Tickets',
        reload:true,
        navLink: '/apps/crm/tickets'
      },
    ]
  },
  {
    id: 'reports_on_demand',
    title: 'Reporting',
    icon: <TbReport size={12} />,
    children: [
      {
        id: 'insights_and_actionables',
        title: 'Data Tracker',
        navLink: '/apps/reporting/data-tracker'
      },
      {
        id: 'report_on_demand',
        title: 'Report On Demand',
        navLink: '/apps/reporting/report-on-demand'
      }
    ]
  },
  // {
  //   id: 'discounts',
  //   title: 'Discounts',
  //   icon: <Percent size={12} />,
  //   children: [
  //     {
  //       id: 'discountsoverview',
  //       title: 'Discounts Overview',
  //       navLink: '/apps/discounts/overview'
  //     },
  //     {
  //       id: 'adddiscounts',
  //       title: 'Optimize Discounts',
  //       navLink: '/apps/discounts/new_discounts'
  //     },
  //     {
  //       id: 'menu_description',
  //       title: 'Menu Description',
  //       navLink: '/apps/menu_management_and_hygiene/menu_description',
  //       disabled: false
  //     },
  //     {
  //       id: 'import_visualization_data',
  //       title: 'Import visualization data',
  //       navLink: '/apps/business_overview_analytics/import_visualization_data'
  //     },
  //   ]
  // },
  {
    id: 'customer-complaints',
    title: 'Customer Complaints',
    icon: <Percent size={12} />,
    children: [
      {
        id: 'Dashboard',
        title: 'Dashboard',
        navLink: '/apps/customer-complaints/Dashboard'
      }
    ]
  },
  {
    id: 'menu_management_and_hygiene',
    title: 'Menu Management',
    // icon: <MdMenuBook size={12} />,
    icon: <Lock size={12} />,
    disabled: true,
    children: [
      // {
      //   id: 'order_insights',
      //   title: 'Order Insights',
      //   navLink: '/apps/business_overview_analytics/order_insights'
      // },      
      {
        id: 'menu_audit',
        title: 'History',
        navLink: '/apps/menu-management/menu-checker',
        disabled: false
      },
      {
        id: 'insights',
        title: 'Insights',
        navLink: '/apps/menu-management/insights',
        disabled: false
      }
    ]
    // children: [
    //   {
    //     id: 'catalogue_management',
    //     title: 'Catalogue Management',
    //     navLink: '/apps/menu_management_and_hygiene/catalogue_management'
    //   },
    //   {
    //     id: 'menu_hygiene',
    //     title: 'Menu Hygiene',
    //     navLink: '/apps/menu_management_and_hygiene/menu_hygiene'
    //   },
    //   {
    //     id: 'page_rating',
    //     title: 'Page Rating',
    //     navLink: '/apps/menu_management_and_hygiene/page_rating'
    //   },
    //   {
    //     id: 'calendar',
    //     title: 'Calendar',
    //     navLink: '/calendar'
    //   },
    //   {
    //     id: 'menu_description',
    //     title: 'Menu Description',
    //     navLink: '/apps/menu_management_and_hygiene/menu_description'
    //   },
    //   {
    //     id: 'item_availability',
    //     title: 'Item Availability',
    //     navLink: '/apps/menu_management_and_hygiene/item_availability',
    //     icon: (
    //       <div>
    //         <Lock size={12} />
    //       </div>
    //     ),
    //     disabled: true
    //   },
    //   {
    //     id: 'price_and_offer_parity',
    //     title: 'Price and Offer Parity',
    //     navLink: '/apps/menu_management_and_hygiene/price_and_offer_parity',
    //     icon: (
    //       <div>
    //         <Lock size={12} />
    //       </div>
    //     ),
    //     disabled: true
    //   },
    //   {
    //     id: 'image_and_description_mapping',
    //     title: 'Image and Description Mapping',
    //     navLink: '/apps/menu_management_and_hygiene/image_and_description_mapping',
    //     icon: (
    //       <div>
    //         <Lock size={12} />
    //       </div>
    //     ),
    //     disabled: true
    //   },
    //   {
    //     id: 'competitor_benchmarking',
    //     title: 'Competitor Benchmarking',
    //     navLink: '/apps/menu_management_and_hygiene/competitor_benchmarking',
    //     icon: (
    //       <div>
    //         <Lock size={12} />
    //       </div>
    //     ),
    //     disabled: true
    //   }
    // ]
  },
  {
    id: 'digital_marketing',
    title: 'Digital Marketing',
    icon: <Lock size={12} />,
    disabled: true
    // children: [
    //   {
    //     id: 'advertising_evaluation',
    //     title: 'Advertising Evaluation',
    //     navLink: '/apps/digital_marketing/advertising_evaluation',
    //     icon: <div><Lock size={12} /></div>,
    //     disabled: true
    //   },
    //   {
    //     id: 'advertising_campaign_builder',
    //     title: 'Advertising Campaign Builder',
    //     navLink: '/apps/digital_marketing/advertising_campaign_builder',
    //     icon: <div><Lock size={12} /></div>,
    //     disabled: true
    //   },
    //   {
    //     id: 'discounting_evaluation',
    //     title: 'Discounting Evaluation',
    //     navLink: '/apps/digital_marketing/discounting_evaluation',
    //     icon: <div><Lock size={12} /></div>,
    //     disabled: true
    //   },
    //   {
    //     id: 'discounting_configuration',
    //     title: 'Discounting Configuration',
    //     navLink: '/apps/digital_marketing/discounting_configuration',
    //     icon: <div><Lock size={12} /></div>,
    //     disabled: true
    //   }
    // ]
  }
];
